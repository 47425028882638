import { render, staticRenderFns } from "./LocationDescription.vue?vue&type=template&id=192e3de1&scoped=true&"
import script from "./LocationDescription.vue?vue&type=script&lang=js&"
export * from "./LocationDescription.vue?vue&type=script&lang=js&"
import style0 from "./LocationDescription.vue?vue&type=style&index=0&id=192e3de1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "192e3de1",
  null
  
)

export default component.exports