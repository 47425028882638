<template>
  <div id="gallery">
    <a v-for='image in images' :data-fancybox="galleryid" :key="image.image" :href="image.image">
      <img :src="image.image" alt='' >
    </a>
  </div>
</template>

<script>

window.$ = window.jQuery = require('jquery');
require("@fancyapps/fancybox");

import "@fancyapps/fancybox/dist/jquery.fancybox.css";


export default {
  name: 'photo-gallery',
  props: ['images', 'galleryid'],
  data() {
    return {
      imageList: []
    }
  },
  mounted() {
    window.$('[data-fancybox="' + this.galleryid + '"]').fancybox({
      // Options will go here
    });
  }
}
</script>

<style scoped>
  #gallery {
    overflow-x: auto;
    overflow-y: hidden;
    height: 150px;
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-bottom: 10px;
  }

  #gallery img {
    height: 150px;
    display: inline-block;
    margin-right: 5px;
  }
</style>

<style>
  .fancybox-container {
    z-index: 999999;
  }
</style>
