<template>
  <div id="app">
    <div v-if='loading && ! unauthenticated'>
      <div class="text-center">
        <div class="spinner-border" role="status" style='width: 5rem; height: 5rem; margin-top: 200px;'>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if='unauthenticated'>
      <AuthForm @authenticate="authenticate" v-bind:authError="authError"/>
    </div>
    <Map
      v-if='!loading && !unauthenticated'
      v-bind:locations="locations"
      v-bind:axios="axios"
      v-bind:locationTypes="locationTypes"
      ref='mainmap'
      @setVisited="setVisited"
      @setLike="setLike"
      @refreshLocation="refreshLocation"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Map from './components/Map.vue'
import AuthForm from './components/AuthForm.vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    Map,
    AuthForm,
  },
  data() {
    return {
      locationsRaw: [],
      locations: [],
      locationTypesRaw: [],
      locationTypes: [],
      loading: true,
      unauthenticated: false,
      authError: false,
      authInProgress: false,
      axios: null,
    }
  },
  created() {
    this.axios = axios.create({
      baseURL: this.$apiURI
    });

    if (localStorage.authToken) {
      this.setAuthToken(localStorage.authToken)
      this.getPoints();
    }
    else
      this.unauthenticated = true;
    this.loading = false;
  },
  methods: {
    getPoints() {
      this.loading = true;
      this.axios.get("/locationTypes/").then(response => {
          this.locationTypesRaw = response.data;
          this.loading = false;
          this.unauthenticated = false;
          this.locationTypesFromLocationTypesRaw();
        }).catch(error => {
          console.log(error);
          this.loading = false;
          this.unauthenticated = true;
          return;
        }).then(
        this.axios.get("/locations/")
          .then(response => {
            this.locationsRaw = response.data;
            this.loading = false;
            this.locationsFromLocationsRaw();
          }));

    },
    setVisited(location, value) {
      this.locations[location].visited_loading = true;

      this.axios.patch('/locations/' + location + '/', {
        visited: value,
      }).then(response => {
        if (response.data.visited === value) {
          this.locations[location].visited_loading = false;
          this.locations[location].visited = value;
        }
      }).catch(function (error) {
        alert("Ошибка " + error);
      });
    },
    setLike(location, value) {
      this.locations[location].like_loading = true;

      this.axios.patch('/locations/' + location + '/', {
        like: value,
      }).then(response => {
        if (response.data.like === value) {
          this.locations[location].like_loading = false;
          this.locations[location].like = value;
        }
      }).catch(function (error) {
        alert("Ошибка " + error);
      });
    },
    authenticate(login, password) {
      this.authInProgress = true;
      axios.post(this.$apiURI + "/api-token-auth/", {
          username: login,
          password: password
        }).then(response => {
          this.authInProgress = false;
          this.authError = false;
          this.unauthenticated = false;
          this.setAuthToken(response.data.token);
          this.getPoints();
        }).catch(error => {
          console.log(error);
          this.unauthenticated = true;
          this.authError = true;
          this.authInProgress = false;
        });
    },
    setAuthToken(token) {
      if (token) {
        localStorage.authToken = token;
        this.axios.defaults.headers.common['Authorization'] = 'Token ' + token;
      }
    },
    refreshLocation(location, launchEdit = false) {
      this.axios.get('/locations/' + location + '/').then(response => {
        Vue.set(this.locations, location, { ...response.data });
        if (launchEdit) {
          this.$nextTick(function () {
              this.$refs.mainmap.editLocation(response.data.id);
          });
        }
      }).catch(error => {
        if (error.response.status === 404) {
          //удаление
          Vue.delete(this.locations, location);
        }
        else
          alert("Ошибка при обновлении точки " + error);
      });
    },
    locationsFromLocationsRaw() {
      var values = {}
      this.locationsRaw.forEach(function(el){
          values[el.id] = el;
      });
      this.locations = values;
    },
    locationTypesFromLocationTypesRaw() {
      var values = {}
      this.locationTypesRaw.forEach(function(el){
          values[el.id] = el;
      });
      this.locationTypes = values;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

html, body, #app {
  width: 100%;
  height: 100%;
}
</style>
