<template>
  <div style="height: 100%; width: 100%;" @keydown.ctrl='markersDraggable = true' @keyup='markersDraggable = false'>
    <div class='mobile-close-overlay' v-if="sidebarOpen" @click='hideSidebar()'></div>
    <b-modal
      size="lg"
      id="add-location-modal"
      ref='add-location-modal'
      title="Добавить точку">

      <b-card-group deck>
        <b-card
          title="Вручную"
        >
          <b-card-sub-title>
            <p style='small text-muted'>Когда окно закроется, нужно кликнуть по карте</p>
          </b-card-sub-title>
          <b-button @click="handleEditManually();" variant="primary">Добавить вручную</b-button>
        </b-card>

        <b-card
          title="По координатам"
        >
          <b-card-sub-title>
            <p style='small text-muted'>Скопировать координаты и вставить в поле ниже</p>
          </b-card-sub-title>
          <b-card-text>
            <b-form-input
              id="input-link"
              v-model="importFromLatLng"
              type="text"
              placeholder="51.80182, 5.73486" />
          </b-card-text>
          <b-button @click="handleAddLocationByLatLng()" variant="primary">Добавить по координатам</b-button>
        </b-card>
      </b-card-group>

<!--
      <b-card-group deck class='mt-4'>
        <b-card
          title="По ссылке"
        >
          <b-card-sub-title>
            <p style='small text-muted'>Google Maps/Яндекс.Карты</p>
          </b-card-sub-title>
          <b-card-text>
            <b-form-input
              id="input-link"
              v-model="importFromLink"
              type="text"
              placeholder="https://" />
          </b-card-text>
          <b-button @click="handleAddLocationByLink()" variant="primary">Добавить по ссылке</b-button>
        </b-card>
      </b-card-group>
-->
      <template v-slot:modal-footer="{ cancel }">
        <b-button @click="cancel()">Отмена</b-button>
      </template>
    </b-modal>
    <div id='sidebar' v-bind:class="{ 'sidebar-collapsed': !sidebarOpen }">
      <LocationDescription
        v-if="selectedLocation"
        v-bind:locations="locations"
        v-bind:locationTypes="locationTypes"
        v-bind:locationImages="locationImages"
        v-bind:selectedLocation="selectedLocation"
        @editLocation="editLocation"
      />
      <LocationEdit
        v-if="selectedLocation"
        ref="locationEdit"
        v-bind:editedLocationDataInput="locations[selectedLocation]"
        v-bind:locationTypes="locationTypes"
        v-bind:locationImages="locationImages"
        v-bind:axios="axios"
        @getLocationImages="getLocationImages"
      />
    </div>
    <div v-if='selectedLocation' id='sidebar-toggle' v-bind:class="{ 'sidebar-toggle-collapsed': !sidebarOpen }" @click='toggleSidebar()'>
      <span v-if='sidebarOpen'>❬</span>
      <span v-else>❭</span>
    </div>
    <div id='map' v-bind:class="{ 'sidebar-map-collapsed': !sidebarOpen }">
      <l-map
        :zoom="zoom"
        :center="center"
        :options="{zoomControl: false}"
        style='width: 100%; height: 100%;'
        ref='map'
        @click='addLocationManually'
      >
        <v-geosearch :options="geosearchOptions" ></v-geosearch>
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />

        <v-marker-cluster>
          <l-marker
            v-for="location in locations"
            :key="location.id"
            :lat-lng="{ lng: location.lng, lat: location.lat }"
            :draggable="markersDraggable"
            @move:latLng="handleMarkerMove($event, location.id)"
            @click="selectLocation(location.id)">
            <l-icon
            :icon-anchor="[15, 42]"
            :icon-size="[30, 42]"
            >
              <div class="pin" v-bind:class="getIconClass(location.visited, location.like)">
                <div class="pin-icon">{{ locationTypes[location.location_type].icon }}</div>
              </div>
            </l-icon>
            <l-tooltip :content="location.title" />
          </l-marker>
        </v-marker-cluster>

        <l-control position="bottomright">
          <div class="custom-control-button" @click="addLocationByLink">+</div>
        </l-control>
        <l-control position="topright">
          <div class="custom-control-button small" @click="openInGoogleMaps">🌎 Google</div>
        </l-control>
        <l-control position="topright">
          <div class="custom-control-button small" @click="openInYandexMaps">🌎 Яндекс</div>
        </l-control>
        <l-control-zoom position="topright"></l-control-zoom>
        <v-locatecontrol :options="{ showCompass: true, drawCircle: true, enableHighAccuracy: true, position: 'topright', 'icon': 'location-icon' }" />
      </l-map>
    </div>
  </div>
</template>

<script>
//import { latLng } from "leaflet";
import Vue from 'vue';
import { LMap, LTileLayer, LMarker, LIcon, LTooltip, LControlZoom, LControl } from "vue2-leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol'
import LocationDescription from './LocationDescription.vue'
import LocationEdit from './LocationEdit.vue'

export default {
  name: "MainMap",
  props: ['locations', 'axios', 'locationTypes'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    VGeosearch,
    LControlZoom,
    LControl,
    'v-locatecontrol': Vue2LeafletLocatecontrol,
    LocationDescription,
    LocationEdit
  },
  data() {
    return {
      zoom: 3,
      path: "/images/",
      center: [47.41322, -1.219482],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 'https://tile.tracestrack.com/en/{z}/{x}/{y}.png?key=5ff6b16748e3734f67f5457045db85ba',
      attribution: '',
      selectedLocation: null,
      editedLocation: null,
      markersDraggable: false,
      sidebarOpen: false,
      locationImages: [],
      importFromLink: null,
      importFromLatLng: null,
      addLocationManuallyInProgress: false,
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
        style: 'bar',
        // showMarker: false,
        // showPopup: true
      },
    };
  },
  methods: {
    selectLocation(location) {
      this.selectedLocation = location;
      this.showSidebar();
      this.getLocationImages(location);
    },
    getLocationImages(location, force = false) {
      if ((this.locationImages[location] === undefined) || force) {
        this.axios.get("/locationImages/?location=" + location + "&ordering=position")
          .then(response => Vue.set(this.locationImages, location, response.data));
      }
    },
    addLocationByLink() {
      this.importFromLink = '';
      this.$bvModal.show('add-location-modal');
    },
    handleAddLocationByLink() {
      this.axios.post("/locations/", {
        import_link: this.importFromLink,
        location_type: 1
      }).then(response => {
        alert(response);
        console.log(response);
      }).catch(response => {
        alert('Ошибка при добавлении по ссылке' + response);
      });
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    showSidebar() {
      this.sidebarOpen = true;
    },
    hideSidebar() {
      this.sidebarOpen = false;
    },
    editLocation(location) {
      this.$refs.locationEdit.startEdit(location);
    },
    addLocationManually(el) {
      if (!this.addLocationManuallyInProgress)
        return;
      this.addLocationManuallyInProgress = false;
      this.axios.post("/locations/", {
        lat: el.latlng.lat,
        lng: el.latlng.lng,
        location_type: 1,
      }).then(response => {
        this.$emit("refreshLocation", response.data.id, true);
        this.selectLocation(response.data.id);
      }).catch(response => {
        alert('Ошибка при добавлении точки ' + response);
      });
    },
    handleAddLocationByLatLng() {
      this.addLocationManuallyInProgress = false;

      if (! this.importFromLatLng.match(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/)) {
        alert('Неправильный формат координат')
        return;
      }

      const latlng = this.importFromLatLng.split(", ");
      const lat = latlng[0].trim();
      const lng = latlng[1].trim();

      this.axios.post("/locations/", {
        lat: lat,
        lng: lng,
        location_type: 1,
      }).then(response => {
        this.$emit("refreshLocation", response.data.id, true);
        this.selectLocation(response.data.id);
        this.importFromLatLng = '';
        this.$nextTick(function () {
            this.$bvModal.hide('add-location-modal');
        });
        this.$refs.map.mapObject.setZoom(20, {
          animate: true,
        });
        this.$refs.map.mapObject.panTo({lat: lat, lng: lng}, {
          animate: true,
        });
      }).catch(response => {
        alert('Ошибка при добавлении точки ' + response);
      });
    },
    handleMarkerMove(latlng, location) {
      // Не работает
      this.axios.patch('/locations/' + location + '/', {
        lat: latlng.lat,
        lng: latlng.lng,
      }).then(response => {
        if (response.status === 200) {
          this.$emit("refreshLocation", location);
        }
      }).catch(function (error) {
        alert("Ошибка " + error);
      });
    },
    getIconClass(visited, like) {
      if (like)
        return 'location-like';

      if (visited)
        return 'location-visited';

      return 'location-not-visited';
    },
    handleEditManually() {
      this.addLocationManuallyInProgress = true;
      this.sidebarOpen = false;
      this.$nextTick(function () {
          this.$bvModal.hide('add-location-modal');
      });
    },
    openInGoogleMaps() {
      var location = this.$refs.map.mapObject.getCenter();
      var zoom = this.$refs.map.mapObject.getZoom();
      window.open("https://www.google.com/maps/@" + location.lat + "," + location.lng + "," + zoom + "z");
    },
    openInYandexMaps() {
      var location = this.$refs.map.mapObject.getCenter();
      var zoom = this.$refs.map.mapObject.getZoom();
      window.open("https://yandex.ru/maps/?ll=" + location.lng + "%2C" + location.lat + "&z=" + zoom);
      //37.613814%2C55.776003&z=15.18
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
  }

  .pin::after {
      content: '';
      width: 24px;
      height: 24px;
      margin: 3px 0 0 3px;
      background: #fff;
      position: absolute;
      border-radius: 50%;
   }

   .pin-icon {
     position: absolute;
     width: 22px;
     font-size: 16px;
     left: 0;
     right: 0;
     margin: 3px auto;
     text-align: center;
     z-index: 2;
     transform: rotate(45deg);
  }

  .custom-control-button {
    background-color: white;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 0 10px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
  }

  .custom-control-button:hover {
    background-color: #f4f4f4;
  }

  .custom-control-button.small {
    font-size: 14px;
  }

  #sidebar {
    width: 310px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    padding: 1em;
    height: 100%;
    border-right: 2px solid rgba(0, 0, 0, 0.3);
    z-index: 9999;
  }

  .sidebar-collapsed {
    left: -310px !important;
  }

  #sidebar-toggle {
    position: fixed;
    background-color: white;
    top: 20px;
    left: 308px;
    width: 20px;
    height: 40px;
    border-radius: 2px;
    border-right: 2px solid rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    border-top: 2px solid rgba(0, 0, 0, 0.3);
    z-index: 10000;
    cursor: pointer;
    padding: 4px 0 0 0;
    text-align: center;
  }

  .sidebar-toggle-collapsed {
    left: -2px !important;
  }

  #map {
    height: 100%;
  }

  .sidebar-map-collapsed {
    margin-left: 0 !important;
  }

  #sidebar, .sidebar-collapsed, #sidebar-toggle, .sidebar-toggle-collapsed, #map, .sidebar-map-collapsed {
    transition: 0.5s all ease;
  }

  .mobile-close-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
    display: none;
  }

  @media only screen and (max-width: 550px) {
    .mobile-close-overlay {
      display: block;
    }
  }

  #add-location-modal___BV_modal_outer_ {
    z-index: 100000 !important;
  }

  .location-like {
    background: #DF2029;
  }

  .location-not-visited {
    background: #0099FF;
  }

  .location-visited {
    background: gray;
    opacity: 0.7;
  }

</style>

<style>
  .location-icon::before {
    content: "☉";
    font-weight: bold;
    font-size: 16px;
  }

  .location-icon {
    display: inline-block;
    margin-top: 1px;
    margin-left: -1px;
  }
</style>
