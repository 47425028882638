<template>
  <div class='location-description'>
    <Gallery
      v-if="locationImages[selectedLocation] && locationImages[selectedLocation].length > 0"
      v-bind:images="locationImages[selectedLocation]"
      galleryid="gallery"
    />
    <h4 class='mb-0'>{{ locationTypes[locations[selectedLocation].location_type].icon }} {{ locations[selectedLocation].title }}</h4>
    <p>
      <small class="text-muted">ID: {{ selectedLocation }},
        добавлено <FormatDate v-bind:date="locations[selectedLocation].added_on" />
        <span v-if=' locations[selectedLocation].import_link'>
           по <a  class='text-muted font-italic' :href='locations[selectedLocation].import_link' target="_blank">ссылке</a>
        </span>
      </small>
    </p>
     <div>
       <p>
         <button type="button" class="btn btn-primary btn-sm" @click='$emit("editLocation", selectedLocation)'>✎ Редактировать</button>&nbsp;

         <button type="button" class="btn btn btn-link btn-sm" v-if="locations[selectedLocation].like_loading" disabled>
           <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           <span class="sr-only">Loading...</span>
         </button>

         <button type="button" class="btn btn btn-link btn-sm" v-if="locations[selectedLocation].like && ! locations[selectedLocation].like_loading" @click='$parent.$emit("setLike", selectedLocation, false)'>♥</button>
         <button type="button" class="btn btn btn-link btn-sm" v-if="! locations[selectedLocation].like && ! locations[selectedLocation].like_loading" @click='$parent.$emit("setLike", selectedLocation, true)'>♡</button>

         <button type="button" class="btn btn btn-link btn-sm" v-if="locations[selectedLocation].visited_loading" disabled>
           <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           <span class="sr-only">Loading...</span>
         </button>

         <button style='font-size: 0.8rem;' type="button" class="btn btn-link btn-sm" v-if="locations[selectedLocation].visited && ! locations[selectedLocation].visited_loading" @click='$parent.$emit("setVisited", selectedLocation, false)'>Посещен</button>
         <button style='font-size: 0.8rem;' type="button" class="btn btn-link btn-sm" v-if="!locations[selectedLocation].visited  && ! locations[selectedLocation].visited_loading" @click='$parent.$emit("setVisited", selectedLocation, true)'>Не посещен</button>

       </p>
     </div>
    <div class='mb-3'>
      <a target="_blank" class="btn btn-sm btn-link" role="button" v-if="locations[selectedLocation].link_website" :href="locations[selectedLocation].link_website">🔗 Сайт</a>
      <a target="_blank" class="btn btn-sm btn-link" role="button" v-if="locations[selectedLocation].link_instagram" :href="locations[selectedLocation].link_instagram">🔗 Instagram</a>
      <a target="_blank" class="btn btn-sm btn-link" role="button" v-if="locations[selectedLocation].link_tripadvisor" :href="locations[selectedLocation].link_tripadvisor">🔗 Tripadvisor</a>
      <a target="_blank" class="btn btn-sm btn-link" role="button" v-if="locations[selectedLocation].link_yandexmaps" :href="locations[selectedLocation].link_yandexmaps">🔗 Яндекс.Карты</a>
      <a target="_blank" class="btn btn-sm btn-link" role="button" v-if="locations[selectedLocation].link_googlemaps" :href="locations[selectedLocation].link_googlemaps">🔗 Google Maps</a>
    </div>
    <div v-if="locations[selectedLocation].address">
      <span class='font-weight-bold'>Адрес:</span>
      <div class='mb-3' style='font-size: 0.8rem;'>{{ locations[selectedLocation].address }}</div>
    </div>
    <div v-if="locations[selectedLocation].notes">
      <span class='font-weight-bold'>Заметки:</span>
      <vue-markdown :anchorAttributes="anchorAttributes" style='font-size: 0.8rem;' :source="locations[selectedLocation].notes"></vue-markdown>
    </div>
    <div class='mt-3' v-if="locations[selectedLocation].description">
      <span class='font-weight-bold'>Описание:</span>
      <vue-markdown :anchorAttributes="anchorAttributes" style='font-size: 0.7rem;' :source="locations[selectedLocation].description"></vue-markdown>
    </div>
  </div>
</template>

<script>
import FormatDate from './FormatDate.vue'
import VueMarkdown from '@adapttive/vue-markdown'
import Gallery from './Gallery.vue'

export default {
  props: ['locations', 'locationTypes', 'selectedLocation', 'locationImages'],
  components: {
    FormatDate,
    VueMarkdown,
    Gallery,
  },
  data() {
    return {
      anchorAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer nofollow'
      }
    }
  }
}
</script>

<style scoped>
  .location-description {
    height: 100%;
    overflow-y: auto;
  }
</style>
