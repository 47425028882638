<template>
  <span>
    <span v-if="extended">{{ date | dateParse('YYYY-MM-DDTHH:mm:ss') | dateFormat('DD.MM.YY HH:mm:ss') }}</span>
    <span v-if="!extended">{{ date | dateParse('YYYY-MM-DD') | dateFormat('DD.MM.YY') }}</span>
  </span>
</template>

<script>

import Vue from 'vue';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);

export default {
  props: ['date', 'extended']
};
</script>
