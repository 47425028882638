<template>
  <form class="form-signin">
    <div class='logo'>🔍🦆</div>
    <h1 class="h3 mb-3 font-weight-normal text-center">В поисках уток</h1>
    <label for="inputLogin" class="sr-only">Логин</label>
    <input type="text" id="inputLogin" class="form-control" placeholder="Логин" required autofocus v-model='login' :disabled="authInProgress">
    <label for="inputPassword" class="sr-only">Пароль</label>
    <input type="password" id="inputPassword" class="form-control" placeholder="Пароль" required v-model='password' :disabled="authInProgress">
    <button class="btn btn-lg btn-primary btn-block" type="button" @click="sendAuthentication()" :disabled="authInProgress">Войти</button>
    <div class="d-flex justify-content-center mt-3" v-if='authInProgress'>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="alert alert-danger mt-3" role="alert" v-if='authError'>
      Неправильный логин или пароль
    </div>
  </form>
</template>

<script>

export default {
  name: 'AuthForm',
  props: ['authError', 'authInProgress'],
  data() {
    return {
      login: "",
      password: "",
    }
  },
  methods: {
    sendAuthentication() {
      this.$emit("authenticate", this.login, this.password);
    }
  }
}
</script>

<style scoped>

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.logo {
  font-size: 45px;
  text-align: center;
}
</style>
