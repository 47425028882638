<template>
<div>
  <b-modal
    v-if="editedLocationData"
    title="Редактировать точку"
    :no-close-on-backdrop="true"
    id="LocationEdit"
    >
    <div class='row'>
      <div class='col-sm-4 small'><a class='mext-muted' @click='showPhotoEditor' href='javascript:;'>Редактировать фото</a></div>
      <div class='col-sm-8'>
        <p class='text-right'>
          <small class="text-muted">ID: {{ editedLocationId }},
            добавлено <FormatDate v-bind:date="editedLocationData.added_on" :extended="true" />
            <span v-if='editedLocationData.import_link'>
               по <a class='text-muted font-italic' :href='editedLocationData.import_link' target="_blank">ссылке</a>
            </span>
          </small>
        </p>
      </div>
    </div>
    <Gallery
      v-if="locationImages[editedLocationId] && locationImages[editedLocationId].length > 0"
      v-bind:images="locationImages[editedLocationId]"
      galleryid='galleryedit'
    />

    <div class="form-group row">
      <label class='col-sm-3 col-form-label' for="input-title">Название</label>
      <div class="col-sm-9">
        <b-form-input
          id="input-title"
          v-model="editedLocationData.title"
          type="text"
          placeholder="Название"
          required />
      </div>
    </div>

    <div class="form-group row">
      <label class='col-sm-3 col-form-label' for="input-location-type">Тип точки</label>
      <div class="col-sm-9">
        <b-form-select
          id="input-location-type"
          v-model="editedLocationData.location_type"
          :options="locationTypesForOptions"
          required/>
      </div>
    </div>

    <div class="form-group row">
      <label class='col-sm-3 col-form-label' for="input-address">Адрес</label>
      <div class="col-sm-9">
        <b-form-input
          id="input-address"
          v-model="editedLocationData.address"
          type="text"
          placeholder="Адрес"
          required />
      </div>
    </div>

    <div class="form-group row">
      <label class='col-sm-3 col-form-label' for="input-notes">Заметки</label>
      <div class="col-sm-9">
        <b-form-textarea
          id="input-notes"
          class='form-control-sm'
          v-model="editedLocationData.notes"
          placeholder="Заметки"
          rows="3"
          max-rows="6" />
      </div>
    </div>

    <div class="form-group row">
      <label class='col-sm-3 col-form-label' for="input-description">Описание</label>
      <div class="col-sm-9">
        <b-form-textarea
          id="input-description"
          class='form-control-sm'
          v-model="editedLocationData.description"
          placeholder="Описание"
          rows="3"
          max-rows="6" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <b-form-checkbox
          v-model="editedLocationData.like"
          :value="true"
          :unchecked-value="false">
          ♥
        </b-form-checkbox>
      </div>
      <div class='col-sm-9'>
        <b-form-checkbox
          v-model="editedLocationData.visited"
          :value="true"
          :unchecked-value="false">
          Посещён
        </b-form-checkbox>
      </div>
    </div>

    <b-card sub-title="Ссылки">
      <div class="form-group row">
        <label class='col-sm-4 col-form-label col-form-label-sm' for="input-website">Сайт</label>
        <div class="col-sm-8">
          <b-form-input
            id="input-website"
            class='form-control-sm'
            v-model="editedLocationData.link_website"
            type="text"
            placeholder="Сайт" />
        </div>
      </div>
      <div class="form-group row">
        <label class='col-sm-4 col-form-label col-form-label-sm' for="input-instagram">Instagram</label>
        <div class="col-sm-8">
          <b-form-input
            id="input-instagram"
            class='form-control-sm'
            v-model="editedLocationData.link_instagram"
            type="text"
            placeholder="Instagram" />
        </div>
      </div>
      <div class="form-group row">
        <label class='col-sm-4 col-form-label col-form-label-sm' for="input-tripadvisor">Tripadvisor</label>
        <div class="col-sm-8">
          <b-form-input
            id="input-tripadvisor"
            class='form-control-sm'
            v-model="editedLocationData.link_tripadvisor"
            type="text"
            placeholder="Tripadvisor" />
        </div>
      </div>
      <div class="form-group row">
        <label class='col-sm-4 col-form-label col-form-label-sm' for="input-yandexmaps">Яндекс.Карты</label>
        <div class="col-sm-8">
          <b-form-input
            id="input-yandexmaps"
            class='form-control-sm'
            v-model="editedLocationData.link_yandexmaps"
            type="text"
            placeholder="Яндекс.Карты" />
        </div>
      </div>
      <div class="form-group row">
        <label class='col-sm-4 col-form-label col-form-label-sm' for="input-googlemaps">Google Maps</label>
        <div class="col-sm-8">
          <b-form-input
            id="input-googlemaps"
            class='form-control-sm'
            v-model="editedLocationData.link_googlemaps"
            type="text"
            placeholder="Google Maps" />
        </div>
      </div>
    </b-card>

    <template #modal-footer>
      <div class='w-100'>
        <div class='float-left'>
          <b-button
            variant="danger"
            :disabled='saveInProgress'
            @click="deleteLocation"
          >
            Удалить
          </b-button>
        </div>
        <div style='text-align: right;' class='float-right'>
          <b-button
            :disabled='saveInProgress'
            @click="cancelEdit"
          >
            Отменить
          </b-button>
          &nbsp;
          <b-button
            variant="primary"
            :disabled='saveInProgress'
            @click="handleSave"
          >
            Сохранить
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
  <b-modal
    v-if="editedLocationData"
    title="Редактировать фото"
    ok-title="Ок"
    :ok-only='true'
    id="PhotoEdit"
    >
    <div class='text-muted small'>Изменения сохраняются автоматически</div>
<!--
    <div class='location-images-edit' v-if="locationImages[editedLocationId] && locationImages[editedLocationId].length > 0">
      <div v-for='image in locationImages[editedLocationId]' :key="image.image">
        <img :src='image.image' alt='' />
      </div>
    </div>
-->

    <drop-list
      class='location-images-reorder-droplist'
      :items="locationImages[editedLocationId]"
      ref='file-reorder'
      @reorder="handleFileReorder"
    >
      <template v-slot:item="{item}">
         <drag :key="item.id" :data="item">
             <div class='location-images-reorder'>
               <div class='location-images-reorder-delete' @click="handleDeleteFile(item.id)">❌</div>
               <img alt='' :src='item.image' />
             </div>
         </drag>
       </template>
    </drop-list>

    <b-form-group class='mt-2' label="Загрузить файл:" label-cols-sm="4" v-if='!fileUploadInProgress'>
      <b-form-file multiple id="file-upload" ref="file-upload" @input='handleFileUpload'></b-form-file>
    </b-form-group>

    <b-form-group class='mt-2' label="Загрузить по ссылке:" label-cols-sm="4" v-if='!fileUploadInProgress'>
      <b-input-group class="mt-3">
        <b-form-input v-model='uploadByLinkLink'></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-success" @click='handleUploadByLink'>Загрузить</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div class="text-center" v-if='fileUploadInProgress'>
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

  </b-modal>
</div>
</template>

<script>
import FormatDate from './FormatDate.vue'
import Gallery from './Gallery.vue'
import { Drag, DropList } from "vue-easy-dnd";

export default {
  props: ['editedLocationDataInput', 'locationTypes', 'locationImages', 'axios'],
  components: {
    FormatDate,
    DropList,
    Drag,
    Gallery,
  },
  data() {
    return {
      editedLocationData: null,
      editedLocationId: null,
      saveInProgress: false,
      fileUploadInProgress: false,
      uploadByLinkLink: '',
    }
  },
  computed: {
    locationTypesForOptions: function() {
      var values = {}
      Object.keys(this.locationTypes).forEach(key => {
        let el = this.locationTypes[key];
        values[key] = { text: el.icon + " " + el.title, value: el.id };
      });
      return values;
    }
  },
  methods: {
    startEdit(location) {
      this.saveInProgress = false;
      this.editedLocationData = { ...this.editedLocationDataInput };
      this.editedLocationId = location;
      this.$nextTick(function () {
          this.$bvModal.show('LocationEdit');
      });
    },
    cancelEdit() {
      this.$nextTick(function () {
          this.$bvModal.hide('LocationEdit');
      })
    },
    deleteLocation() {
      this.$bvModal.msgBoxConfirm('Вы уверены?', {
        id: 'DeleteConfirm',
        okTitle: 'Да',
        cancelTitle: 'Отмена'
      })
        .then(value => {
          if (value) {
            this.saveInProgress = true;
            this.axios.delete('/locations/' + this.editedLocationId + '/').then(response => {
              if (response.status === 204) {
                this.$nextTick(function () {
                    this.$bvModal.hide('LocationEdit');
                });
                this.saveInProgress = false;
                this.$parent.$emit("refreshLocation", this.editedLocationId);
              }
            }).catch(function (error) {
              alert("Ошибка " + error);
            });
          }
        });
    },
    handleSave(bvModalEvt) {
      this.saveInProgress = true;
      bvModalEvt.preventDefault()

      this.axios.patch('/locations/' + this.editedLocationId + '/', {
        title: this.editedLocationData.title,
        location_type: this.editedLocationData.location_type,
        address: this.editedLocationData.address,
        notes: this.editedLocationData.notes,
        description: this.editedLocationData.description,
        like: this.editedLocationData.like,
        visited: this.editedLocationData.visited,
        link_website: this.editedLocationData.link_website,
        link_instagram: this.editedLocationData.link_instagram,
        link_tripadvisor: this.editedLocationData.link_tripadvisor,
        link_yandexmaps: this.editedLocationData.link_yandexmaps,
        link_googlemaps: this.editedLocationData.link_googlemaps,
      }).then(response => {
        if (response.status === 200) {
          this.$nextTick(function () {
              this.$bvModal.hide('LocationEdit');
          });
          this.saveInProgress = false;
          this.$parent.$emit("refreshLocation", this.editedLocationId);
        }
      }).catch(function (error) {
        alert("Ошибка " + error);
      });
    },
    showPhotoEditor() {
      this.$nextTick(function () {
          this.$bvModal.show('PhotoEdit');
      });
    },
    handleFileUpload(files) {
      this.fileUploadInProgress = true;
      files.forEach(async (file) => {
          let formData = new FormData();
          formData.append('image', file);
          formData.append('location', this.editedLocationId);

          await this.axios.post("/locationImages/", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then(response => {
            if (response.status === 201) {
              this.$emit("getLocationImages", this.editedLocationId, true);
            }
          }).catch(response => {
            console.log(response);
            alert('Ошибка при загрузке картинки ' + response);
          });
        });
      this.fileUploadInProgress = false;
      this.$emit("getLocationImages", this.editedLocationId, true);
    },
    handleUploadByLink() {
      this.fileUploadInProgress = true;

      this.axios.post("/locationImages/", {
        location: this.editedLocationId,
        import_image: this.uploadByLinkLink,
      }).then(response => {
        if (response.status === 201) {
          this.$emit("getLocationImages", this.editedLocationId, true);
        }
        this.fileUploadInProgress = false;
        this.uploadByLinkLink = '';
      }).catch(response => {
        console.log(response);
        alert('Ошибка при загрузке картинки ' + response);
        this.fileUploadInProgress = false;
      });
    },
    handleFileReorder(event) {
      event.apply(this.locationImages[this.editedLocationId]);

      let i=0;
      this.locationImages[this.editedLocationId].forEach(el => {
        this.axios.patch('/locationImages/' + el.id + '/', {
          position: i++,
        }).then(response => {
          if (response.status === 200) {
            return;
          }
        }).catch(function (error) {
          alert("Ошибка " + error);
        });
      });
    },
    handleDeleteFile(file_id) {
      this.axios.delete("/locationImages/" + file_id).then(response => {
        if (response.status === 204) {
          this.$emit("getLocationImages", this.editedLocationId, true);
        }
      }).catch(response => {
        console.log(response);
        alert('Ошибка удалении ' + response);
      });
    }
  }
}
</script>

<style scoped>
  .location-images-reorder {
    display: inline-block;
    position: relative;
  }

  .location-images-reorder-delete {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 10px;
  }

  .location-images-reorder img {
    height: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    pointer-events: none;
  }

  .location-images-reorder-droplist .dnd-drag {
    display: inline-block;
  }

  #LocationEdit___BV_modal_outer_ {
    z-index: 100000 !important;
  }

  #PhotoEdit___BV_modal_outer_ {
    z-index: 100001 !important;
  }

  #DeleteConfirm___BV_modal_outer_ {
    z-index: 100001 !important;
  }
</style>
